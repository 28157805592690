import React from 'react';
import SubNavBar from '../SubNavBar/SubNavBarContainer';

const ContentLayout = ({ id, className, children, subNav, pageContext, activeSubNavLabel, pageTitle }) => {
  return (
    <>
      {subNav && (
        <SubNavBar
          pageTitle={pageTitle}
          parentTitle={subNav.name}
          subNavTitle={activeSubNavLabel?.label || pageTitle}
          menuItems={subNav.menuItems}
          pageContext={pageContext}
        />
      )}
      <main id={id} className={className}>
        {children}
      </main>
    </>
  );
};

export default ContentLayout;
