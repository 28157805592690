import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { Link } from 'gatsby';

import CTA from './CTA';

function CTAInternalLink({ link, ariatext, onClick, ...ctaProps }) {
  return (
    <Link className="CTA CTAInternalLink" aria-label={ariatext} to={link} onClick={onClick}>
      <CTA {...ctaProps} />
    </Link>
  );
}

CTAInternalLink.propTypes = checkExtraProps({
  link: PropTypes.string.isRequired,
  ariatext: PropTypes.string,
  onClick: PropTypes.func,
  ...CTA.type.propTypes,
});

CTAInternalLink.defaultProps = { ...CTA.type.defaultProps };

export default memo(CTAInternalLink);
