import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';

import './ColorBlock.scss';

function ColorBlock({ children, color, className, ...props }) {
  const { bgImage } = useStaticQuery(graphql`
    query HeaderQuery {
      bgImage: file(name: { eq: "Blurred_Background_compressed" }) {
        publicURL
      }
    }
  `);

  const styles =
    color === 'blurry'
      ? { style: { background: `url(${bgImage?.publicURL}) no-repeat center center fixed`, backgroundSize: 'cover' } }
      : {};

  return (
    <div className={classnames('ColorBlock', color, className)} {...props} {...styles}>
      {children}
    </div>
  );
}

ColorBlock.propTypes = checkExtraProps({
  color: PropTypes.string.isRequired,
});

ColorBlock.defaultProps = {};

export default memo(ColorBlock);
