import React from 'react';
import { Link } from 'gatsby';

import './TermWrapper.scss';

const TermWrapper = ({ terms }) => {
  return (
    <div className="TermWrapper">
      {terms?.nodes?.map((term, index) => (
        <div key={index} className="newstagterm">
          <Link to={term.uri}>{term.name}</Link>
        </div>
      ))}
    </div>
  );
};

export default TermWrapper;
