import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { Link } from 'gatsby';
import classnames from 'classnames';

function GatsbyInternalLink({ children, link, className, ...props }) {
  return (
    <Link to={link} {...props} className={classnames({ GatsbyExternalLink: true, [className]: className })}>
      {children}
    </Link>
  );
}

GatsbyInternalLink.propTypes = checkExtraProps(
  {
    link: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    activeClassName: PropTypes.string,
    'aria-label': PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
  },
  ['target'],
);

export default memo(GatsbyInternalLink);
