import loadable from '@loadable/component';

const AcfComponents = {
  Hero: loadable(() => import('../Hero/Hero')),
  AfterHero: loadable(() => import('../AfterHero/AfterHero')),
  AfterHeroStatement: loadable(() => import('../AfterHeroStatement/AfterHeroStatement')),
  CardRow: loadable(() => import('../CardRow/CardRow')),
  Grid: loadable(() => import('../Grid/Grid')),
  Carousel: loadable(() => import('../Carousel/Carousel')),
  Zigzag: loadable(() => import('../ZigZag/ZigZag')),
  VaspForm: loadable(() => import('../VaspForm/VaspForm')),
  PreFooter: loadable(() => import('../PreFooter/PreFooter')),
  Faq: loadable(() => import('../FAQ/FAQ')),
  TitleCta: loadable(() => import('../TitleCTA/TitleCTA')),
  ImageCta: loadable(() => import('../ImageCta/ImageCta')),
  JobBoard: loadable(() => import('../JobBoard/JobBoard')),
  List: loadable(() => import('../List/List')),
  LiveDataTracker: loadable(() => import('../LiveDataTracker/LiveDataTracker')),
  TwoOverlappingImages: loadable(() => import('../TwoOverlappingImages/TwoOverlappingImages')),
  WhitepaperSection: loadable(() => import('../WhitepaperSection/WhitepaperSection')),
  InfographicVideo: loadable(() => import('../InfographicVideo/InfographicVideo')),
  DeepDiveChapter: loadable(() => import('../Chapter/DeepDiveChapter')),
  DownloadPdf: loadable(() => import('../DownloadPDF/DownloadPDF')),
  Document: loadable(() => import('../Document/Document')),
  Thanks: loadable(() => import('../Thanks/Thanks')),
  MissionStatement: loadable(() => import('../MissionStatement/MissionStatement')),
  PostArchive: loadable(() => import('../PostArchive/PostArchive')),
  BlogsPreview: loadable(() => import('../BlogsPreview/BlogsPreview')),
  EventsPreview: loadable(() => import('../EventsPreview/EventsPreview')),
  UpdatesPreview: loadable(() => import('../UpdatesPreview/UpdatesPreview')),
  NetworkUpdatesPreview: loadable(() => import('../NetworkUpdatesPreview/NetworkUpdatesPreview')),
  ClaSignCta: loadable(() => import('../SignCTA/SignCTA')),
  ClaHelpPopup: loadable(() => import('../Help/Help')),
  PrivacyPage: loadable(() => import('../SectionBasedContent/SectionBasedContent')),
  SectionBasedContent: loadable(() => import('../SectionBasedContent/SectionBasedContent')),
  WhitepaperPage: loadable(() => import('../WhitePaperPage/WhitePaperPage')),
  ClaSignCorporation: loadable(() => import('../SignCorporation/SignCorporation')),
  ClaSignIndividual: loadable(() => import('../SignIndividual/SignIndividual')),
  ClaSign: loadable(() => import('../Sign/Sign')),
  Section: loadable(() => import('../Section/Section')),
};

export default AcfComponents;
