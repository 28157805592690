import React, { memo, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import SubNav from './SubNav';
import { useNavBarScrollState } from '../../util';
import { PathnameContext } from '../Layout/Layout';

function SubNavBarContainer(props) {
  const ref = useRef();
  const scrollState = useNavBarScrollState(ref);

  const pathname = useContext(PathnameContext);

  return <SubNav ref={ref} sticky {...scrollState} {...props} pathname={pathname} />;
}

SubNavBarContainer.propTypes /* remove-proptypes */ = checkExtraProps(
  {
    white: PropTypes.bool,
    hidden: PropTypes.bool,
    pageTitle: PropTypes.string,
    subNavIdentifier: PropTypes.string,
    activeSubNavLabel: PropTypes.string,
    menuItems: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string.isRequired,
          label: PropTypes.string,
          target: PropTypes.string,
        }),
      ),
    }).isRequired,
  },
  ['pageContext'],
);

export default memo(SubNavBarContainer);
