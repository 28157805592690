import React from 'react';
import AcfComponents from './AcfComponents';

const FlexibleLayout = ({ location, layout, title, pageContext, jumpToLinks, archiveNodes, archiveTerms }) => {
  if (!layout?.__typename) {
    return (
      <section id={layout?.id} className="page missing">
        <div className="inner">
          Some ACF component is missing, which is not passed as layout. This means something in the schema is broken for
          this component. Check build console errors.<span>🙅‍</span>
        </div>
      </section>
    );
  }

  // let layoutSpecificProps = {};

  const indexOfLastUnderscore = layout?.__typename.lastIndexOf('_');

  const type = layout.__typename.substring(indexOfLastUnderscore + 1);
  const ComponentName = AcfComponents[type];

  if (!ComponentName) {
    return (
      <section id={layout?.id} className="page missing">
        <div className="inner">
          The ACF component <strong>"{layout.__typename}"</strong> is missing. <span>🙅‍</span>
        </div>
      </section>
    );
  }

  let extraData = {};

  if (type === 'Hero') {
    extraData = {
      jumpToLinks,
    };
  } else if (type === 'PostArchive') {
    extraData = {
      archiveNodes,
      archiveTerms,
    };
  }

  return (
    // TODO: only pass jumpToLinks, pageContext and title where it is important.
    <ComponentName
      // location={location}
      pageContext={pageContext}
      title={title}
      data={layout}
      {...extraData}
    />
  );
};

export default FlexibleLayout;
