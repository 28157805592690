import React, { memo, forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import classnames from 'classnames';
import GatsbyLink from '../GatsbyLink/GatsbyLink';
import SubHamburgerButton from '../SubHamburgerButton/SubHamburgerButton';
import { lockBodyScroll, trackNavigationClick } from '../../util';
import { useWindowClick } from '../../util';
import './SubNav.scss';

// Warning: Do not use directly! Always import SubNavContainer instead in order to add a SubNav to a page
const className = 'NavBar SubNav';

function SubNavBar(
  {
    menuItems,
    parentTitle,
    subNavTitle,
    sticky,
    white,
    scrollingUp,
    shouldStickyAppear,
    hidden,
    pathname,
    pageContext,
    ...props
  },
  ref,
) {
  const [isSubHamburgerOpen, setSubHamburgerOpen] = useState(false);
  useEffect(() => {
    lockBodyScroll.unlock(true);
    setSubHamburgerOpen(false);
    return () => {
      lockBodyScroll.unlock(true);
    };
  }, [pathname]);

  function toggleSubMenu(e) {
    e.stopPropagation();
    !isSubHamburgerOpen ? lockBodyScroll.lock() : lockBodyScroll.unlock();
    setSubHamburgerOpen(!isSubHamburgerOpen);
  }

  useWindowClick(windowClick);

  function windowClick(e) {
    closeMenu();
  }

  function closeMenu() {
    setSubHamburgerOpen(false);
    lockBodyScroll.unlock();
  }

  if (!menuItems) {
    return null;
  }
  return (
    <nav
      className={classnames(className, {
        sticky,
        white,
        scrollingUp,
        shouldStickyAppear,
        isSubHamburgerOpen,
      })}
      aria-hidden={hidden}
      ref={ref}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="container">
        <div className="currentPage">
          <span className="currentparent">{parentTitle}</span>
          <span className="currentslash">/</span>
          <span className="currenttitle">{subNavTitle}</span>
        </div>
        <ul className="subnav-ul">
          {menuItems.nodes?.map(({ url, label, target }, index) => (
            <li key={label} onClick={closeMenu}>
              <GatsbyLink
                to={url}
                activeClassName="active"
                onClick={() => trackNavigationClick(`Media_${label}`)}
                aria-label={label}
                target={target}
                partiallyActive={index > 0}
              >
                {label}
              </GatsbyLink>
            </li>
          ))}
        </ul>
        <SubHamburgerButton isWhite={white} isOpen={isSubHamburgerOpen} handleClick={toggleSubMenu} />
      </div>
    </nav>
  );
}

const FinalSubNavBar = memo(forwardRef(SubNavBar));

FinalSubNavBar.propTypes /* remove-proptypes */ = checkExtraProps({
  sticky: PropTypes.bool,
  white: PropTypes.bool,
  scrollingUp: PropTypes.bool,
  shouldStickyAppear: PropTypes.bool,
  hidden: PropTypes.bool,
  pathname: PropTypes.string,
  pageTitle: PropTypes.string,
  pageContext: PropTypes.object.isRequired,
  parentTitle: PropTypes.string,
  subNavTitle: PropTypes.string,
  menuItems: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        label: PropTypes.string,
        target: PropTypes.string,
      }),
    ),
  }).isRequired,
});

FinalSubNavBar.defaultProps = {
  sticky: false,
  white: true,
};

export default FinalSubNavBar;
