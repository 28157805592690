import React, { memo, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './JumpToLinks.scss';

import { ReactComponent as DownArrowSvg } from '../../assets/svgs/down-arrow.svg';

import { hashManager, customPropTypes, isBrowser, getDangerousUnsanitizedHash } from '../../util';

function JumpToLinks({ hashLinks, motionState }) {
  useEffect(() => {
    hashManager.checkHashOnLoad();
  }, []);

  useEffect(() => {
    const hashLinkedSections = hashLinks.map(({ id }) => document.getElementById(id)).filter((e) => e);

    function interSectionCallback(entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          hashManager.changeByScroll(entry.target.id);
        } else {
          // Elements will only be able to change his own hash
          if (getDangerousUnsanitizedHash().replace('#', '') === entry.target.id) {
            hashManager.reset();
          }
        }
      });
    }

    const observer = new IntersectionObserver(interSectionCallback, {
      root: null,
      rootMargin: '-10% 0px -60%',
      threshold: 0,
    });

    hashLinkedSections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      hashLinkedSections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, [hashLinks]);

  if (!isBrowser || hashLinks.length === 0) {
    // it should only render in client side by design
    // rendering a placeholder for non browser environment
    // or if there is no hashLinks data
    return <nav className="JumpToLinks" />;
  }

  return (
    <nav className={classnames('JumpToLinks', motionState)}>
      <div className="jump-to-title">
        {/* TODO: Have global translation Strings */}
        On this page
        {/* {general.global_settings.global_copy.jump_to_links} */}
        <DownArrowSvg className="jump-to-icon" />
      </div>
      <ul className="jump-to-links">
        {hashLinks.map(({ id, jumpToLinkTitle }, index) => (
          <li key={id} style={{ transitionDelay: `${0.13 * index}s, ${0.13 * index}s` }}>
            <a href={`#${id}`} onClick={(e) => hashManager.changeByClick(e, id)}>
              {jumpToLinkTitle}
              <span className="underline" />
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

JumpToLinks.propTypes = checkExtraProps({
  title: PropTypes.string,
  hashLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      jumpToLinkTitle: PropTypes.string.isRequired,
    }),
  ).isRequired,
  motionState: customPropTypes.motionStatePropTypes,
});

JumpToLinks.defaultProps = {};

export default memo(JumpToLinks);
