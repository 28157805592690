import React, { memo } from 'react';
// import PropTypes from 'prop-types';
// import checkExtraProps from '@jam3/react-check-extra-props';

import './Section.scss';
import { graphql } from 'gatsby';
import FlexibleLayout from '../Acf/FlexibleLayout';
import ColorBlock from '../ColorBlock/ColorBlock';
import classnames from 'classnames';

// function Section({ data, id, pageContext, children, containerRef }) {
//   let Component = data?.flexibleChildren
//     ? data?.flexibleChildren.map((layout, index) => {
//       return <FlexibleLayout key={index} layout={layout} pageContext={pageContext} />;
//     })
//     : children;
//
//   Component = data?.padding
//     ? (
//       <section className="Section">
//         <div className="container">
//           {Component}
//         </div>
//       </section>
//     )
//     : Component;
//   Component = data?.backgroundColor && data.backgroundColor !== 'none'
//     ? (
//       <ColorBlock color={data?.backgroundColor}>
//         {Component}
//       </ColorBlock>
//     )
//     : Component;
//
//   return React.cloneElement(
//     Component,
//     {
//       id,
//       ref: containerRef,
//     },
//   );
//
//   // return <Component id={id} ref={containerRef} />;
// }

function Section({ data, id, pageContext, children, containerRef, className }) {
  const FlexLayoutChildren = () => (
    <>
      {data?.flexibleChildren
        ? data?.flexibleChildren.map((layout, index) => {
            return <FlexibleLayout key={index} layout={layout} pageContext={pageContext} />;
          })
        : children}
    </>
  );
  if (data?.padding && data?.backgroundColor && data.backgroundColor !== 'none') {
    return (
      <ColorBlock id={id} ref={containerRef} color={data?.backgroundColor}>
        <section className={classnames('Section', className)} ref={containerRef}>
          <div className="container">
            <FlexLayoutChildren />
          </div>
        </section>
      </ColorBlock>
    );
  } else if (data?.padding) {
    return (
      <section className={classnames('Section', className)} id={id} ref={containerRef}>
        <div className="container">
          <FlexLayoutChildren />
        </div>
      </section>
    );
  } else if (data?.backgroundColor && data.backgroundColor !== 'none') {
    return (
      <ColorBlock id={id} ref={containerRef} color={data.backgroundColor}>
        <FlexLayoutChildren />
      </ColorBlock>
    );
  }

  return (
    <section className={classnames('Section', className)} id={id} ref={containerRef}>
      <div className="container">
        <FlexLayoutChildren />
      </div>
    </section>
  );
}

// Section.propTypes = checkExtraProps({
//   id: PropTypes.string,
// });

Section.defaultProps = {};

export default memo(Section);

export const fragments = graphql`
  fragment Section on WpPage_Flexiblelayout_FlexibleChildren_Section {
    backgroundColor
    padding
    flexibleChildren {
      __typename
      ...Hero_Section
      ...AfterHero_Section
      ...AfterHeroStatement_Section
      ...CardRow_Section
      ...Grid_Section
      ...Carousel_Section
      ...VaspForm_Section
      ...ZigZag_Section
      ...PreFooter_Section
      ...Faq_Section
      ...ImageCta_Section
      ...TitleCta_Section
      ...JobBoard_Section
      ...List_Section
      ...LiveDataTracker_Section
      ...TwoOverlappingImages_Section
      ...WhitepaperSection_Section
      ...InfographicVideo_Section
      ...DeepDiveChapter_Section
      ...DownloadPdf_Section
      ...Document_Section
      ...Thanks_Section
      ...MissionStatement_Section
      ...BlogsPreview_Section
      ...EventsPreview_Section
      ...UpdatesPreview_Section
      ...NetworkUpdatesPreview_Section
      ...ClaSignCta_Section
      ...ClaHelpPopup_Section
      ...SectionBasedContent_Section
      ...PrivacySectionBasedContent_Section
      ...WhitepaperPage_Section
      ...ClaSignCorporation_Section
      ...ClaSignIndividual_Section
      ...ClaSign_Section
    }
  }
`;
