import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import CTA from './CTA';
import CTAExternalLink from './CTAExternalLink';
import CTAInternalLink from './CTAInternalLink';

function CTALink(props) {
  const { link } = props;

  if (!link) {
    return null;
  }

  if (link.startsWith('http') || link.startsWith('mailto')) {
    return <CTAExternalLink {...props} />;
  } else {
    return <CTAInternalLink {...props} />;
  }
}

// TODO: should unify with CTAInternalLink and CTAExternalLink
CTALink.propTypes = checkExtraProps({
  link: PropTypes.string.isRequired,
  ariatext: PropTypes.string,
  onClick: PropTypes.func,
  ...CTA.type.propTypes,
});

export default memo(CTALink);
