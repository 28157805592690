import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { ReactComponent as Arrow } from '../../assets/svgs/arrow.svg';

import './ViewAll.scss';
import GatsbyLink from '../GatsbyLink/GatsbyLink';

function ViewAll({ link, alignRight }) {
  return (
    <div className={`viewallwrapper ${alignRight ? 'right' : ''}`}>
      <div className="viewspace" />
      <div className="viewall">
        <GatsbyLink to={link}>
          {/* TODO: Needs to be translated */}
          View all
          <span className="underline" />
        </GatsbyLink>
      </div>
      <div className="viewall arrow-container next">
        <Arrow />
      </div>
    </div>
  );
}

export default memo(ViewAll);

ViewAll.propTypes = checkExtraProps({
  link: PropTypes.string,
  alignRight: PropTypes.bool,
});
