import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkExtraProps from '@jam3/react-check-extra-props';
import { ReactComponent as DownArrow } from '../../assets/svgs/sub-nav-down.svg';
import { ReactComponent as UpArrow } from '../../assets/svgs/sub-nav-up.svg';
import './SubHamburgerButton.scss';

function SubHamburgerButton({ isOpen, isWhite, handleClick }) {
  return (
    <div className={classnames('SubHamburgerButton', { isOpen, isWhite })} onClick={handleClick} id="subnavdown">
      {isOpen ? <UpArrow /> : <DownArrow />}
    </div>
  );
}

SubHamburgerButton.propTypes = checkExtraProps({
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  isWhite: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
});

SubHamburgerButton.defaultProps = {};

export default memo(SubHamburgerButton);
