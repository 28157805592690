import React, { memo, forwardRef } from 'react';
import classnames from 'classnames';
import checkExtraProps from '@jam3/react-check-extra-props';
import PropTypes from 'prop-types';
import { ReactComponent as BackArrowSvg } from '../../assets/svgs/back-arrow.svg';
import { customPropTypes, useCSSMotion } from '../../util';
import GatsbyInternalLink from '../GatsbyLink/GatsbyInternalLink';
import './BackButton.scss';

function BackButton({ motionState, url, title }) {
  if (!url) {
    return null;
  }

  return (
    <div className={classnames('BackButton', motionState)}>
      <GatsbyInternalLink link={url}>
        <span className="back-title">
          <BackArrowSvg className="back-icon" />
          {title}
        </span>
      </GatsbyInternalLink>
    </div>
  );
}

// TODO: adjust PropTypes
BackButton.propTypes = checkExtraProps({
  motionState: customPropTypes.motionStatePropTypes,
  pageContext: PropTypes.shape({
    mobile_back_button: PropTypes.shape({
      text: PropTypes.string,
      permalink: PropTypes.string,
    }),
  }),
});

BackButton.defaultProps = {
  pageContext: {
    mobile_back_button: {
      text: 'Back',
      permalink: '/',
    },
  },
};

function BackButtonWrapper(props, ref) {
  const motionState = useCSSMotion(ref);
  return <BackButton {...props} motionState={motionState} />;
}

export default memo(forwardRef(BackButtonWrapper));
