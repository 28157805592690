import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import CTA from './CTA';

function CTAExternalLink({ link, onClick, ...ctaProps }) {
  return (
    <a href={link} onClick={onClick} className="CTA CTAExternalLink" target="_blank" rel="noopener noreferrer">
      <CTA {...ctaProps} />
    </a>
  );
}

CTAExternalLink.propTypes = checkExtraProps({
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  ...CTA.type.propTypes,
});

CTAExternalLink.defaultProps = { ...CTA.type.defaultProps };

export default memo(CTAExternalLink);
